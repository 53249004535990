import {getCookie} from "tiny-cookie";
import {SmartStorage} from "smart-core-util";

export const tenant = {
    data () {
        return {
            proposalId: '',
            supplierId: '',
            eventData: {},
            proposalSupplier: {},
            cancelClause: {},
            taskStatus: 0,
            taskId: '',
        }
    },
    computed: {
    },
    created () {
        this.eventData = this.$cookie.get("eventData");
        if (this.eventData) {
            this.eventData = JSON.parse(this.eventData)
            this.proposalId = this.eventData.proposalId;
        } else {
            this.proposalId = this.$SmartStorage.get("proposalId");
        }
        this.supplierId = this.$SmartStorage.get('supplierId')
        this.taskId = this.$SmartStorage.get('taskId')
        this.orderId = this.$SmartStorage.get('orderId')
        this.taskStatus = this.$SmartStorage.get('taskStatus')
        this.init()
    },
    methods: {
        init () {
            this.GetProposalItems()
            this.getTips()
        },
        async GetProposalItems () {
            let params = {
                SupplierId: this.supplierId,
                ProposalId: this.proposalId,
                orderId: this.orderId,
            }
            let res = await this.$service.QueryOrderSupplierItemDetail(params)
            if (res && res.success) {
                this.loadType = '';
                this.venueList = []
                this.mealList = []
                this.accommodationList = []
                this.otherList = []
                this.demandList = res.content.items
                this.proposalSupplier = res.content[0].itsSuppliers[0]
                this.proposalSupplier.quoteDeadTime = this.proposalSupplier.quoteDeadTime ? this.proposalSupplier.quoteDeadTime.replace('T', ' ') : ''
                this.cancelClause = JSON.parse(this.proposalSupplier.cancelClause || '{}')
                res.content.forEach((ele, index) => {
                    ele.key = new Date().getTime() + index
                    ele.dtStart = ele.dtStart.replace('T', ' ')
                    ele.dtEnd = ele.dtEnd.replace('T', ' ')
                    switch (ele.itemType) {
                        case 1:
                            this.venueList.push(ele)
                            break
                        case 2:
                            this.mealList.push(ele)
                            break
                        case 3:
                            this.accommodationList.push(ele)
                            break
                        case 4:
                            this.otherList.push(ele)
                            break
                    }
                })
            }
        },
        closeWebview () {
            this.$router.go(-1)
        },
        async getTips () {
            let params = {
                collection: "cfg-ivenue-tips",
                filter: {
                    tenantCode: getCookie('tenant') || SmartStorage.get("tenant") || SmartStorage.get("tenant_code"),
                    origin: 'quotedPrice',
                }
            }
            let res = await this.$service.getsettings(params)
            if (res && res.success) {
                this.tips = res.content ? res.content.content : {}
            }
        }
    }
}
